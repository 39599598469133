@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    /* COLORS */
    --backgroundLight: rgb(240, 239, 233);
    --fontLight: rgb(0, 0, 0);
    --backgroundDark: ;
    --fontDark: ;
}
:root {
    /* SIZES */
    --headerHeight: 90px;
    --cardWidth: ;
    --cardHeight: ;
}

.App {
    background-color: var(--backgroundLight);
    color: var(--fontLight);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.Header {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--backgroundLight);
    width: 100%;
    height: var(--headerHeight);
}
.logoContainer {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* background-color: red; */
    width: 210px;
    position: relative;
    height: calc(var(--headerHeight) - 20px);
}
.headerText {
    position: absolute;
    right: 0;
    margin: 0;
    margin-right: 2px;
    font-size: 3.3rem;
    font-weight: 800;
}
.headerSubtext {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: 0%;
    margin-right: 5%;
    font-size: 0.8rem;
}
.textCursor {
    position: absolute;
    height: 67%;
    width: 4px;
    background-color: black;
    right: 0;
    top: 0;
    margin-top: 8px;
    margin-right: -5px;
    animation: blink 1.4s infinite steps(2);
}

@keyframes blink {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(2);
    }
}

.BlogContents {
    position: relative;
    display: grid;
    padding-top: 40px;
    gap: 90px;
    justify-content: center;
    width: 100%;
    height: 520px;
    overflow-y: scroll;
    margin-top: 10px;
    background-color: var(--backgroundLight);
    margin-top: var(--headerHeight);
}

.blogCard {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    width: 300px;
    height: 250px;
    background-color: var(--backgroundLight);
    border: solid 4px black;
    border-radius: 8% 0 8% 0;
}
.blogCard:hover {
    background-color: black;
    color: white;
}
.blogCard::after,
.blogCard::before {
    pointer-events: none;
    z-index: 1;
    content: '';
    position: absolute;
    width: 108%;
    height: 108%;
    border: solid 5px black;
    border-radius: 8% 8% 8% 8%;
}
.blogCard::after {
    right: -5px;
    top: -26px;
}
.blogCard::before {
    left: -5px;
    bottom: -26px;
}

.blogTitle {
    margin: 0;
    font-size: 1.6rem;
}
.blogInfo {
    position: absolute;
    bottom: 0;
    margin-bottom: -10px;
    display: flex;
}
.infoText {
    margin-top: 0;
}

.blogPage {
    z-index: 3;
    padding: 0px;
    background-color: rgb(243, 250, 229);
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    flex-direction: column;

    gap: 10px;
    overflow-y: scroll;
}
.bTitle,
.bHeader1,
.bHeader2,
.bText,
.bTextUnderline,
.bTextItalic,
.bImage {
    margin: 0;
    width: 90%;
    max-width: 500px;
}

.bHeader1 {
    font-size: 2.4rem;
    font-weight: 700;
}
.bHeader2 {
    font-size: 1.6rem;
    font-weight: 500;
}
.bText {
    font-size: 1.2rem;
}
.bTextUnderline {
    text-decoration: underline;
}
.bTextItalic {
    font-style: italic;
}
.bTextBold {
    font-weight: bold;
}
.bImage {
    display: inline-block;
    width: 80%;
}
.bImageDesc {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    width: 80%;
    margin-top: -10px;
    height: 30px;
    background-color: rgba(0, 0, 0, 20%);
}
